import * as React from "react";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { format } from "date-fns";
import { enUS } from "date-fns/locale";

import { If } from "../../../../../common/components/If";
import {
	Box,
	CircularProgress,
	Typography,
	SxProps,
	Theme,
} from "@mui/material";

import type { ContractPeriodClickObjectType } from "../../../../../requests_cm/gecoContractsService/types";
import type { MappedClicksDataByYearT, ViewByT } from "./types";

const style: { [key: string]: SxProps<Theme> } = {
	loaderContainer: {
		minHeight: "600px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
};

interface ProductionOverTimeGraphProps extends React.PropsWithChildren<{}> {
	data?: Array<ContractPeriodClickObjectType>;
	installedCapacity?: number;
	noData?: boolean;
	selectedYear: string;
	viewBy: ViewByT;
	sortedByDate: MappedClicksDataByYearT;
}

export default function BarsChartClicks(props: ProductionOverTimeGraphProps) {
	const chartComponentRef = React.useRef<HighchartsReact.RefObject>(null);
	const { noData, viewBy, selectedYear, sortedByDate } = props;

	const [chartData, setChartData] = React.useState<Highcharts.Options>({});

	React.useEffect(() => {
		const chart = chartComponentRef.current?.chart;
		if (sortedByDate[selectedYear][viewBy].length === 0) {
			chart?.showLoading("Loading...");
		} else {
			chart?.hideLoading();
			const { categories, seriesData } = sortedByDate[selectedYear][
				viewBy
			]
				.filter(Boolean)
				.reduce(
					(acc, item) => {
						const category =
							viewBy === "month"
								? format(new Date(item.click_start), "MMM", {
										locale: enUS,
								  })
								: item.period;
						//@ts-ignore
						acc.categories.push(category);
						//@ts-ignore
						acc.seriesData.push(item.volume_percentage);

						return acc;
					},
					{ categories: [], seriesData: [] }
				);
			setChartData({
				chart: {
					type: "column",
				},
				credits: {
					enabled: false,
				},
				title: {
					text: "",
					align: "left",
				},
				xAxis: {
					type: "category",
					labels: {
						autoRotation: [-45, -90],
					},
					categories,
				},
				yAxis: {
					min: 0,
					max: 100,
					title: {
						text: "Volume (%)",
					},
				},
				legend: {
					enabled: false,
				},
				tooltip: {
					pointFormat: "<b>{point.y:.1f} %</b>",
				},
				series: [
					{
						name: "Population",
						colors: [
							"#2B72FB",
							"#64BDC6",
							"#EECA34",
							"#FE6A35",
							"#FA4B42",
							"#EE60E0",
							"#7B47E9",
							"#5D89DF",
							"#6AD1FE",
							"#3FDC7E",
							"#2B72FB",
							"#64BDC6",
						],
						colorByPoint: true,
						data: seriesData,
						type: "column",
					},
				],
			});
		}
	}, [viewBy, selectedYear]);
	return (
		<>
			<If condition={Object.keys(chartData).length === 0 && !noData}>
				<Box sx={style.loaderContainer}>
					<CircularProgress color="primary" />
				</Box>
			</If>
			<If condition={Object.keys(chartData).length > 0}>
				<HighchartsReact
					highcharts={Highcharts}
					options={chartData}
					ref={chartComponentRef}
					containerProps={{
						"data-testid": "clickBarChart",
					}}
					{...props}
				/>
			</If>
			<If condition={noData}>
				<Typography paragraph>{"No Data Found"}</Typography>
			</If>
		</>
	);
}
