import * as React from "react";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { If } from "../../../../../common/components/If";
import {
	Box,
	CircularProgress,
	Typography,
	SxProps,
	Theme,
} from "@mui/material";

const style: { [key: string]: SxProps<Theme> } = {
	loaderContainer: {
		minHeight: "600px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
};

interface ProductionOverTimeGraphProps extends React.PropsWithChildren<{}> {
	data?: {
		clicked: {
			volume: string;
			volume_mwh: string;
		};
		available: {
			volume: string;
			volume_mwh: string;
		};
	};
	installedCapacity?: number;
	noData?: boolean;
}

export default function PieChartClicks(props: ProductionOverTimeGraphProps) {
	const { data, noData } = props;

	const [chartData, setChartData] = React.useState<any>({});

	React.useEffect(() => {
		setChartData({
			chart: {
				plotBackgroundColor: null,
				plotBorderWidth: 0,
				plotShadow: false,
			},
			credits: {
				enabled: false,
			},
			subtitle: {
				text: "Clicked volume",
				align: "center",
				verticalAlign: "middle",
				y: 0,
				style: {
					color: "#888888",
					fontSize: "12px",
				},
			},
			title: {
				text: `${data?.clicked.volume}%`,
				align: "center",
				verticalAlign: "middle",
				y: 25,
				style: {
					padingTop: "10px",
					fontSize: "16px",
				},
			},
			tooltip: {
				pointFormat: "<b>{point.percentage:.1f}%</b>",
			},
			accessibility: {
				point: {
					valueSuffix: "%",
				},
			},
			plotOptions: {
				pie: {
					dataLabels: {
						enabled: false,
						distance: -50,
						style: {
							fontWeight: "bold",
							color: "white",
						},
					},
					startAngle: -90,
					endAngle: 90,
					center: ["50%", "100%"],
					size: "220%",
				},
			},
			series: [
				{
					type: "pie",
					name: "Browser share",
					innerSize: "80%",
					data: [
						["Clicked", Number(data?.clicked.volume)],
						["Available", Number(data?.available.volume)],
					],
				},
			],
			colors: ["#4caefe", "#adb5bd"],
		});
	}, [data]);
	return (
		<>
			<If condition={Object.keys(chartData).length === 0 && !noData}>
				<Box sx={style.loaderContainer}>
					<CircularProgress color="primary" />
				</Box>
			</If>
			<If condition={Object.keys(chartData).length > 0}>
				<HighchartsReact
					highcharts={Highcharts}
					options={chartData}
					containerProps={{
						"data-testid": "clickPieChart",
					}}
					{...props}
				/>
			</If>
			<If condition={noData}>
				<Typography paragraph>{"No Data Found"}</Typography>
			</If>
		</>
	);
}
