import SimpleTable, {
	HeadersType,
} from "../../../../common/components/SimpleTable/SimpleTable";
import { v4 as uuidv4 } from "uuid";
import {
	clickabilitySortableColumns,
	useCreateContractPeriodClickObjectMutation,
	useDeleteClickObjectMutation,
	useGetContractPeriodClickObjectsQuery,
} from "../../../../requests_cm/gecoContractsService/service";
import CircularProgress from "@mui/material/CircularProgress";
import YesNoTag from "../../../../common/components/YesNoTag";
import { Spacer } from "../../../../common/components/Spacer";
import { useEffect, useMemo, useState } from "react";
import {
	ClickUnit,
	ContractDetailType,
	ContractPeriodClickObjectType,
	ContractPeriodType,
} from "../../../../requests_cm/gecoContractsService/types";
import { Box, IconButton } from "@mui/material";
import { FluidButton } from "../../../../common/components/FluidButton";
import InfoBlock from "../../../../common/components/InfoBlock/InfoBlock";
import ClickabilityModal from "./ClickabilityModal";
import { style } from "./ClickabilityTab.style";
import ClickModal from "./ClickModal";
import { NJButton } from "@engie-group/fluid-design-system-react";
import { useGetClickPeriodsQuery } from "../../../../requests_cm/gecoReferentialService/service";
import ClickabilityTabFilters from "./ClickabilityTabFilters";
import { ClickFormik } from "../../formik/clickabilityTabFormik";
import { FieldValue } from "../../../../common/components/FiltersDrawer";
import {
	ErrorType,
	formatApiErrorMessage,
} from "../../../../common/utils/formatApiErrorMessage";
import { useRtkQueryDynamicEndpoint } from "../../../../common/hooks/useRtkQueryDynamicEndpoint";
import { useIsUserWithinGroups } from "../../../../common/hooks/useIsUserWithinGroups";
import { Groups } from "../../../authentication/authentication.constant";
import { PageInfoType } from "../../../../requests_cm/gecoTypes";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { isBeforeOrEqual } from "../../../../common/utils/dateUtils";
import ErrorMessageModal from "../../../../common/components/ErrorMessageDialog";
import OverviewModal from "./OverviewModal";
import type { NewClickOverviewT } from "./OverviewModal/types";

export interface ClickabilityTabProps {
	contract?: ContractDetailType;
	contractPeriodId: number;
	onSaveDraftContract: (contract: ContractDetailType) => void;
}

const ClickabilityTab = ({
	contract,
	contractPeriodId,
	onSaveDraftContract,
}: ClickabilityTabProps) => {
	const [pageInfo, setPageInfo] = useState<PageInfoType>({
		page: 1,
	});
	const [isClickabilityModalOpen, setClickabilityModalOpen] = useState(false);
	const [isClickModalOpen, setClickModalOpen] = useState(false);
	const [overviewModalOpen, setOverviewModalOpen] = useState(false);

	const [newClickOverview, setNewClickOverview] = useState<
		NewClickOverviewT | undefined
	>();
	const [filtersOpen, setFiltersOpen] = useState(false);
	const [filters, setFilters] = useState<Record<string, FieldValue>>({});
	const [
		createClickBase,
		{
			isLoading: isCreatingClick,
			error: clickPostError,
			isSuccess: isClickSuccess,
		},
	] = useCreateContractPeriodClickObjectMutation();

	const createClick = useRtkQueryDynamicEndpoint(createClickBase);

	const { data: clickPeriods } = useRtkQueryDynamicEndpoint(
		useGetClickPeriodsQuery
	)({});
	const { isUserAuthorized } = useIsUserWithinGroups();
	const { data, isLoading, isFetching } = useRtkQueryDynamicEndpoint(
		useGetContractPeriodClickObjectsQuery
	)({ contract_period_id: contractPeriodId, ...filters, ...pageInfo });

	const [deleteClickObjectBase, { error: errorDeleteClick, reset }] =
		useDeleteClickObjectMutation();
	const deleteClickObject = useRtkQueryDynamicEndpoint(deleteClickObjectBase);

	const isUserAuthorizedBool = isUserAuthorized([
		Groups.geco_admin,
		Groups.geco_trader,
	]);

	const clickObjects =
		data?.result.map((click) => ({
			...click,
			ot_sync: <YesNoTag yes={click.ot_sync} />,
			click_period_id:
				clickPeriods?.find(
					(ct) => String(ct.id) === String(click.click_period_id)
				)?.name ?? click.click_period_id,
			actions: (
				<div style={{ display: "flex", justifyContent: "flex-end" }}>
					<IconButton
						onClick={() =>
							deleteClickObject({
								contractPeriod_id: contractPeriodId,
								click_id: click.id,
							})
						}
						aria-label="delete"
						disabled={isBeforeOrEqual(
							new Date(click.click_start),
							new Date()
						)}
					>
						<DeleteOutlineIcon />
					</IconButton>
				</div>
			),
		})) ?? [];

	const contractPeriod: ContractPeriodType | undefined = useMemo(() => {
		return contract?.contract_periods.filter(
			(cp: ContractPeriodType) => cp.id === Number(contractPeriodId)
		)[0];
	}, [contract, contractPeriodId]);

	useEffect(() => {
		if (isClickSuccess) setClickModalOpen(false);
		if (newClickOverview) setClickModalOpen(true);
	}, [isClickSuccess, newClickOverview]);

	const headers: HeadersType<ContractPeriodClickObjectType>[] = [
		{ label: "Click Date", accessor: "click_date" },
		{ label: "Click Start", accessor: "click_start" },
		{ label: "Click End", accessor: "click_end" },
		{ label: "Period", accessor: "click_period_id" },
		{ label: "Price", accessor: "price" },
		{ label: "Mid Price", accessor: "mid_price" },
		{ label: "Volume %", accessor: "volume_percentage" },
		{ label: "Volume in Mwh", accessor: "volume_absolute" },
		{ label: "OT Sync", accessor: "ot_sync" },
		{ label: "Last Sync", accessor: "last_sync_update" },
		{ label: "", accessor: "actions" },
	].map((header) => ({
		...header,
		sortable: clickabilitySortableColumns.includes(header.accessor),
	})) as HeadersType<ContractPeriodClickObjectType>[];

	// fixing multi clickability. Will be removed when full feature developed.
	const firstClickability = useMemo(() => {
		if (
			contractPeriod?.clickability &&
			contractPeriod?.clickability.length > 0
		) {
			return contractPeriod?.clickability[0];
		}
	}, [contractPeriod?.clickability]);

	return (
		<>
			<Spacer gap={24} />
			<Box sx={style.container.clickability}>
				<InfoBlock
					info={[
						{
							label: "Click Period",
							value: firstClickability?.clickability_information
								?.click_period_frequency,
						},
						{
							label: "Click Type",
							value: firstClickability?.clickability_information
								?.type,
						},
						{
							label: "Max Capacity",
							value: firstClickability?.max_capacity,
						},
						{
							label: "Min Capacity",
							value: firstClickability?.min_capacity,
						},
						{
							label: "Max Pcr Clickable",
							value: firstClickability?.max_click_percentage,
						},
						{
							label: "Min Pcr Clickable",
							value: firstClickability?.min_click_percentage,
						},
						{
							label: "Alpha",
							value: firstClickability?.clickability_information
								?.alpha?.commodity,
						},
						{
							label: "Beta",
							value: firstClickability?.clickability_information
								?.beta?.commodity,
						},
						{
							label: "Beta Fees",
							value: (
								firstClickability?.clickability_information
									?.beta?.fees || []
							).reduce(
								(accumulator, currentValue) =>
									accumulator + currentValue.value,
								0
							),
						},
					]}
				/>
				<Box sx={style.container.clickability.edit}>
					<FluidButton
						icon={"mode_edit"}
						label={"Edit Clickability"}
						onClick={() => setClickabilityModalOpen(true)}
						isDisabled={!isUserAuthorizedBool}
					/>
				</Box>
			</Box>
			<Spacer gap={24} />
			{isLoading && <CircularProgress />}
			{clickObjects && !isLoading && (
				<Box>
					<Spacer gap={24} />
					<Box sx={style.buttonWrapper}>
						<Box sx={style.buttonSpace}>
							<NJButton
								icon="bar_chart"
								label="Clickability overview"
								emphasis="subtle"
								onClick={() => setOverviewModalOpen(true)}
								isDisabled={!isUserAuthorizedBool}
							/>
						</Box>
						<Box sx={style.buttonSpace}>
							<NJButton
								icon="add_circle_outline"
								label="Add Click"
								emphasis="subtle"
								onClick={() => setClickModalOpen(true)}
								isDisabled={!isUserAuthorizedBool}
							/>
							<FluidButton
								label={"Filters"}
								icon="filter_list"
								onClick={() => setFiltersOpen(true)}
							/>
						</Box>
					</Box>
					<Spacer gap={24} />

					<ClickabilityTabFilters
						clickTypes={clickPeriods || []}
						isOpen={filtersOpen}
						defaultFilters={filters}
						onClose={() => setFiltersOpen(false)}
						onApplyFilters={setFilters}
					/>
					<SimpleTable
						dataTestid="clickabilityTable"
						headers={headers}
						items={clickObjects}
						isFetching={isFetching}
						pageInfo={pageInfo}
						setPageInfo={setPageInfo}
						infiniteScroll
					/>
					<ErrorMessageModal
						title={"Failed to delete Click"}
						content={formatApiErrorMessage(
							errorDeleteClick as ErrorType
						)}
						actionAfterClose={reset}
					/>
				</Box>
			)}
			<ClickabilityModal
				isOpen={isClickabilityModalOpen}
				handleClose={() => setClickabilityModalOpen(false)}
				onSaveDraftContract={onSaveDraftContract}
				contract={contract}
				contractPeriodId={contractPeriodId}
			/>
			{data && data.result.length && clickPeriods && contractPeriod && (
				<OverviewModal
					contractPeriod={contractPeriod}
					dataClicksRaw={data.result}
					dataPeriodList={clickPeriods}
					isOpen={overviewModalOpen}
					handleClose={() => setOverviewModalOpen(false)}
					handleNewClick={(newOverviewClick: NewClickOverviewT) =>
						setNewClickOverview(newOverviewClick)
					}
				/>
			)}

			{!!contractPeriod && (
				<ClickModal
					isOpen={isClickModalOpen}
					isLoading={isCreatingClick}
					newClickOverview={newClickOverview}
					createClickErrorMessage={formatApiErrorMessage(
						clickPostError as ErrorType
					)}
					clickPeriods={clickPeriods || []}
					handleClose={() => {
						setClickModalOpen(false);

						setNewClickOverview(undefined);
					}}
					onCreateClick={(newClick: ClickFormik) => {
						const formattedClick = {
							...newClick,
							click_period_id: newClick.click_period_id
								? Number(newClick.click_period_id)
								: undefined,
							volume: Number(
								newClick.click_unit === ClickUnit.PERCENTAGE
									? newClick.volumePct
									: newClick.volume
							),
						};

						delete formattedClick.volumePct;

						createClick({
							...formattedClick,
							contract_period_id: contractPeriodId,
						});
					}}
					contractPeriod={contractPeriod}
					newClickUuid={uuidv4()}
				/>
			)}
		</>
	);
};

export default ClickabilityTab;
