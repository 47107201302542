import { Box, DialogActions } from "@mui/material";
import FluidSelect from "../../common/components/FluidSelect";
import { Spacer } from "../../common/components/Spacer";
import FluidDatePicker from "../../common/components/FluidDatePicker";
import { style } from "./style";
import { PrimaryButton } from "../../common/components/CustomButton";
import {
	ContractPeriodType,
	GetHorizonChangeParams,
} from "../../requests_cm/gecoContractsService/types";
import { Formik } from "formik";
import { ErrorText } from "../../common/components/ErrorText";
import { Toggle } from "../../common/components/Toggle";

export interface DateChangeStepProps {
	onSubmit: (params: Partial<GetHorizonChangeParams>) => void;
	onCancel: () => void;
	isLoading: boolean;
	contractPeriod: ContractPeriodType | undefined;
	error: string | string[];
}

export const DateChangeStep = ({
	onCancel,
	onSubmit,
	error,
	contractPeriod,
	isLoading,
}: DateChangeStepProps) => {
	return (
		<Box sx={style.dateChangeContainer}>
			<Formik
				enableReinitialize={true}
				initialValues={{
					field_name: "start_date",
					old_value: contractPeriod?.start_date || "",
					new_value: contractPeriod?.start_date || "",
					turn_safety_off: false,
					realign_related_contracts: false,
				}}
				onSubmit={(data: {
					old_value: string;
					new_value: string;
					field_name: "start_date" | "end_date";
					turn_safety_off: boolean;
					realign_related_contracts: boolean;
				}) => {
					onSubmit(data);
				}}
			>
				{({
					handleChange,
					handleSubmit,
					values,
					errors,
					setFieldValue,
				}) => (
					<form onSubmit={handleSubmit}>
						<Spacer gap={24} />
						<Box sx={style.dateChangeContainer.fieldTypeContainer}>
							<FluidSelect
								isLabelStatic
								items={[
									{
										label: "Start Date",
										value: "start_date",
									},
									{ label: "End Date", value: "end_date" },
								]}
								label={"Changes for"}
								name={"field_name"}
								// @ts-ignore does not provides an HTMLElement as it should but the value itself
								onChange={(
									field: "start_date" | "end_date"
								) => {
									setFieldValue("field_name", field);
									setFieldValue(
										"old_value",
										contractPeriod![field]
									);
									setFieldValue(
										"new_value",
										contractPeriod![field]
									);
								}}
								value={values.field_name}
								errorMessage={errors.field_name}
							/>
						</Box>
						<Spacer gap={24} />
						<Box
							sx={style.dateChangeContainer.datePickersContainer}
						>
							<FluidDatePicker
								onChange={handleChange}
								name={"old_value"}
								label={"Old Value"}
								value={values.old_value}
								disabled
								errorMessage={errors.old_value}
							/>
							<FluidDatePicker
								onChange={handleChange}
								name={"new_value"}
								label={"New Value"}
								value={values.new_value}
								errorMessage={errors.new_value}
							/>
						</Box>
						<Spacer gap={24} />

						<Toggle
							name={"turn_safety_off"}
							label={"Agreeing that it is unsafe"}
							description={
								"By ticking you are responsible for the applied changes"
							}
							onChange={handleChange}
							isTrue={!!values.turn_safety_off}
						/>
						<Spacer gap={24} />
						<Toggle
							name={"realign_related_contracts"}
							label={"Re-align related contracts"}
							description={
								"Will search for contracts on the same servicepoints and propose changes for all dates that are currently aligned"
							}
							onChange={handleChange}
							isTrue={!!values.realign_related_contracts}
						/>

						<Spacer gap={24} />
						<DialogActions>
							<PrimaryButton
								onClick={onCancel}
								text={"Cancel"}
								type="button"
								color="secondary"
							></PrimaryButton>
							<PrimaryButton
								loader={isLoading}
								disabled={values.old_value === values.new_value}
								text={"Check Impacts"}
								type="submit"
								color="primary"
							></PrimaryButton>
						</DialogActions>
						<ErrorText>{error}</ErrorText>
					</form>
				)}
			</Formik>
		</Box>
	);
};
