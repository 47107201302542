import _ from "lodash";
import {
	AlphaOrBeta,
	ClickabilityInformation,
	ClickabilityType,
	ClickLagType,
	ClickUnit,
	ContractDetailType,
	ContractPeriodType,
	LagBusinessDayConvention,
	LagCalendar,
} from "../../../requests_cm/gecoContractsService/types";
import {
	getContractPeriodFromId,
	getContractPeriodIndexFromId,
} from "./common";
import { format } from "date-fns";
import { OtClickPeriods } from "../../../requests_cm/gecoReferentialService/types";
import type { NewClickOverviewT } from "../ContractPeriodTabs/ClickabilityTab/OverviewModal/types";

export interface ClickabilityFormik {
	click_period: OtClickPeriods | undefined;
	click_type: ClickabilityType | undefined;
	right_to_seller: "0" | "1";
	max_capacity: number | undefined;
	min_capacity: number | undefined;
	max_click_percentage: number | undefined;
	min_click_percentage: number | undefined;
	max_nb_click: number | undefined;
	max_nb_declick: number | undefined;
	allow_full_declick: boolean;
	alpha: number | undefined;
	beta: number | undefined;
	aggregation_fee: number | undefined;
	service_fee: number | undefined;
	last_click_lag: number;
	click_lag_type: ClickLagType | undefined;
	lag_business_day_convention: LagBusinessDayConvention | undefined;
	lag_calendar: LagCalendar | undefined;
}

export interface ClickFormik {
	click_date: string | undefined;
	click_end: string | undefined;
	click_start: string | undefined;
	click_unit: ClickUnit | undefined;
	mid_price: number | undefined;
	name: string | undefined;
	price: number | undefined;
	trader_name: string | undefined;
	click_period_id: string | undefined;
	volume: number | undefined;
	volumePct: string | undefined;
	clickYear: string | undefined;
}
function calculateMaxClickableVolume(
	totalAvailableVolume: string,
	monthAvailablePercentage: number,
	sumOfAllAvailablePercentages: number
) {
	const availableVolumes = Number(totalAvailableVolume);
	const monthAvailableDecimal = monthAvailablePercentage * 0.01;
	const sumOfAllAvailableDecimal = sumOfAllAvailablePercentages * 0.01;

	const maxClickableVolume =
		(availableVolumes * monthAvailableDecimal) / sumOfAllAvailableDecimal;

	return parseFloat(maxClickableVolume.toFixed(2));
}

export const clickFormikInitalValueMapper = (
	contractPeriod: ContractPeriodType | undefined,
	initialClickPeriodId: string | undefined,
	uuid: string,
	formikHistory: ClickFormik | undefined,
	newClickOverview: NewClickOverviewT | undefined
) => {
	if (newClickOverview) {
		const volume = calculateMaxClickableVolume(
			newClickOverview.availableVolumeAbsolute,
			newClickOverview.volume_available,
			Number(newClickOverview.availableVolumePercentage) * 12
		);

		return {
			...newClickOverview,
			click_period_id: String(newClickOverview.click_period_id),
			name: `${contractPeriod?.name} #${uuid}`,
			click_date: format(new Date(), "yyyy-MM-dd"),
			click_unit: ClickUnit.MWH,
			trader_name: undefined,
			mid_price: undefined,
			price: undefined,
			volume,
			volumePct: String(newClickOverview.volume_available),
			clickYear: undefined,
		};
	}
	if (formikHistory) {
		return {
			...formikHistory,
			name: `${contractPeriod?.name} #${uuid}`,
			click_date: format(new Date(), "yyyy-MM-dd"),
		};
	}
	return {
		click_period_id: initialClickPeriodId,
		click_date: format(new Date(), "yyyy-MM-dd"),
		click_end: contractPeriod?.end_date,
		click_start: contractPeriod?.start_date,
		click_unit: ClickUnit.MWH,
		clickYear: undefined,
		mid_price: undefined,
		name: `${contractPeriod?.name} #${uuid}`,
		price: undefined,
		trader_name: undefined,
		volume: undefined,
		volumePct: undefined,
	};
};

export const clickabilityTabFormikInitialValueMapper = (
	contract: ContractDetailType | undefined,
	contractPeriodId: number
): ClickabilityFormik => {
	const clickability = getContractPeriodFromId(
		contract?.contract_periods || [],
		contractPeriodId
	)?.clickability[0];
	// taking the first clickability, will be removed during complete feature development.

	return {
		click_period:
			clickability?.clickability_information?.click_period_frequency,
		click_type: clickability?.clickability_information?.type,
		alpha: clickability?.clickability_information?.alpha?.commodity,
		beta: clickability?.clickability_information?.beta?.commodity,
		aggregation_fee: (
			clickability?.clickability_information?.beta?.fees || []
		).find((fee) => fee.name == "aggregation_fee")?.value,
		service_fee: (
			clickability?.clickability_information?.beta?.fees || []
		).find((fee) => fee.name == "service_fee")?.value,
		last_click_lag:
			clickability?.clickability_information?.last_click_lag || 15, // default is 15
		click_lag_type: clickability?.clickability_information?.click_lag_type,
		lag_business_day_convention:
			clickability?.clickability_information?.lag_business_day_convention,
		lag_calendar: clickability?.clickability_information?.lag_calendar,
		right_to_seller: clickability?.right_to_seller ? "1" : "0",
		max_capacity: clickability?.max_capacity,
		min_capacity: clickability?.min_capacity,
		max_click_percentage: clickability?.max_click_percentage,
		min_click_percentage: clickability?.min_click_percentage,
		max_nb_click: clickability?.max_nb_click,
		max_nb_declick: clickability?.max_nb_declick,
		allow_full_declick: clickability?.allow_full_declick || false,
	};
};

export const clickabilityFormikToContractPeriodMapper = (
	clickabilityFormik: ClickabilityFormik,
	contractPeriod: ContractPeriodType
): ContractPeriodType => {
	const aggregationFee = {
		...((
			contractPeriod?.clickability[0]?.clickability_information?.beta
				?.fees || []
		).find((fee) => fee.name == "aggregation_fee") || {}),
		value: clickabilityFormik.aggregation_fee,
	};

	const serviceFee = {
		...((
			contractPeriod?.clickability[0]?.clickability_information?.beta
				?.fees || []
		).find((fee) => fee.name == "service_fee") || {}),
		value: clickabilityFormik?.service_fee,
	};

	const alpha: AlphaOrBeta = {
		..._.cloneDeep(
			contractPeriod?.clickability[0]?.clickability_information?.alpha
		),
		commodity: clickabilityFormik?.alpha as number,
		fees:
			_.cloneDeep(
				contractPeriod?.clickability[0]?.clickability_information?.alpha
					.fees
			) || [],
	};

	const beta: AlphaOrBeta = {
		..._.cloneDeep(
			contractPeriod?.clickability[0]?.clickability_information?.beta
		),
		commodity: clickabilityFormik?.beta as number,
		fees:
			_.cloneDeep(
				contractPeriod?.clickability[0]?.clickability_information?.beta
					?.fees
			) || [],
	};

	// @ts-ignore
	beta.fees = beta.fees.map((fee) => {
		if (fee.name == "service_fee") {
			return serviceFee;
		}
		if (fee.name == "aggregation_fee") {
			return aggregationFee;
		}
		return fee;
	});

	// @ts-ignore
	const clickabilityInformation: ClickabilityInformation = {
		..._.cloneDeep(
			contractPeriod?.clickability[0]?.clickability_information
		),
		click_period_frequency:
			clickabilityFormik.click_period as OtClickPeriods,
		type: clickabilityFormik.click_type as ClickabilityType,
		last_click_lag: clickabilityFormik.last_click_lag,
		click_lag_type: clickabilityFormik.click_lag_type as ClickLagType,
		lag_business_day_convention:
			clickabilityFormik.lag_business_day_convention as LagBusinessDayConvention,
		lag_calendar: clickabilityFormik.lag_calendar as LagCalendar,
		alpha,
		beta,
	};

	// Needs update on mutli clickability development
	contractPeriod.clickability = [
		{
			..._.cloneDeep(contractPeriod?.clickability[0]),
			right_to_seller: clickabilityFormik.right_to_seller === "1",
			max_capacity: clickabilityFormik.max_capacity as number,
			min_capacity: clickabilityFormik.min_capacity as number,
			max_click_percentage:
				clickabilityFormik.max_click_percentage as number,
			min_click_percentage:
				clickabilityFormik.min_click_percentage as number,
			max_nb_click: clickabilityFormik.max_nb_click as number,
			max_nb_declick: clickabilityFormik.max_nb_declick as number,
			allow_full_declick: clickabilityFormik.allow_full_declick || false,
			clickability_information: clickabilityInformation,
		},
	];

	return contractPeriod;
};

export const clickabilityFormikToContractMapper = (
	clickabilityFormik: ClickabilityFormik,
	initialContract: ContractDetailType,
	contractPeriodId: number
): ContractDetailType => {
	const updatedContract = _.cloneDeep(initialContract) as ContractDetailType;
	const contractPeriodIndex = getContractPeriodIndexFromId(
		updatedContract.contract_periods,
		contractPeriodId
	);
	const contractPeriod = getContractPeriodFromId(
		updatedContract.contract_periods,
		contractPeriodId
	) as ContractPeriodType;

	updatedContract.contract_periods[contractPeriodIndex] =
		clickabilityFormikToContractPeriodMapper(
			clickabilityFormik,
			contractPeriod as ContractPeriodType
		);

	return updatedContract;
};

export const clickabilityFormikOverrideToAllContractPeriodMapper = (
	clickabilityFormik: ClickabilityFormik,
	initialContract: ContractDetailType
): ContractDetailType => {
	const updatedContract = _.cloneDeep(initialContract) as ContractDetailType;
	for (const contractPeriod of updatedContract?.contract_periods) {
		clickabilityFormikToContractPeriodMapper(
			clickabilityFormik,
			contractPeriod
		);
	}
	return updatedContract;
};
