import {
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormHelperText,
	FormLabel,
	Grid,
	IconButton,
	Switch,
	SxProps,
	Theme,
	Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import en from "date-fns/locale/en-GB";
import CheckboxesTags from "../../../common/components/CheckboxesTags";
import CustomDatePicker from "../../../common/components/CustomDatePicker";
import CustomInput from "../../../common/components/CustomInput";
import {
	CHAPTER51_NUMBER_OF_HOURS,
	CHAPTER51_TYPES,
	CLICK_GRANULARITY,
	PRICING_GRANULARITY,
} from "../PricingRequestStepper.constant";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { lightGrey } from "../../../core/theme";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { selectCommodityFixingsState } from "../PricingRequestStepper.selector";
import { CommodityFixings } from "../PricingRequestStepper.slice";
import CustomSelect from "../../../common/components/CustomSelect";
import { endOfYear } from "date-fns";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { ClickabilityGranularity } from "../pricingRequestStepper.schema";
import _ from "lodash";
import { useTemporaryFetch } from "../../../common/hooks/useTemporaryFetch";
import { Tender } from "../../tender_page/tender.module";
import {
	PricingRequestData,
	PricingRequestDataKeys,
} from "../pricingRequestStepper.module";
import PricingTemplateInput from "./PricingTemplateSelect";

const style: { [key: string]: SxProps<Theme> } = {
	alignedProupe: {
		flexDirection: "row",
		textTransform: "capitalize",
		marginBottom: "5px",
	},
	titlePricingType: {
		marginBottom: "32px",
		fontWeight: 700,
	},
	titleOptions: {
		fontWeight: 700,
	},
	datePicker: {
		width: "100%",
		"& p": {
			backgroundColor: lightGrey,
		},
	},
	adjustmentPower: {
		width: "200px",
		"& p": {
			backgroundColor: lightGrey,
		},
	},
	granularityCheckBox: {
		flexDirection: "column",
		textTransform: "capitalize",
	},
	clickCount: {
		width: "180px",
		marginBottom: "1em",
		"& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
			{
				WebkitAppearance: "none",
				margin: 0,
			},
		"& input": {
			textAlign: "center",
		},
		"& input::placeholder": {
			textAlign: "center",
		},
		"& svg": {
			color: "grey",
		},
	},
	pricingDate: {
		width: "100%",
	},
};

interface PricingStepProps {
	values: PricingRequestData;
	handleChange: any;
	setFieldValue: (fieldName: string, value: any) => void;
	errors: any;
	touched: any;
	tenderId?: number;
}

function PricingStep(props: PricingStepProps) {
	const { values, handleChange, setFieldValue, errors, touched, tenderId } =
		props;
	const commodityFixingsList = useSelector(selectCommodityFixingsState);
	const { data: tender, fetchTemporaryData: fetchTender } =
		useTemporaryFetch<Tender>((response) => response.data);

	const [resultOptionsDisabled, setResultOptionsDisabled] = useState(true);
	const [baseProdDisabled, setBaseProdDisabled] = useState(true);

	useEffect(() => {
		if (tenderId && !tender) {
			fetchTender("/tenders/" + tenderId);
		}
	}, [tender, tenderId]);

	useEffect(() => {
		if (tender) {
			setFieldValue(
				"hasNegativePrice",
				!!tender?.has_negative_price_clause
			);
		}
	}, [tender]);

	useEffect(() => {
		const indexes = values?.pricingType ?? [];
		const hasIndexPrice =
			indexes.findIndex(
				(index) => index.name !== "Fix" && index.name !== "Click"
			) >= 0;

		const isBaseProdIndex =
			indexes.findIndex((index) => !!index.is_baseprod) >= 0;

		setFieldValue("baseOptionSwitch", isBaseProdIndex);

		setBaseProdDisabled(!isBaseProdIndex);
		setResultOptionsDisabled(!hasIndexPrice);
	}, [values.pricingType]);

	useEffect(() => {
		if (!resultOptionsDisabled) {
			setFieldValue("alpha", true);
		} else {
			setFieldValue("alpha", false);
			setFieldValue("beta", false);
		}
	}, [resultOptionsDisabled]);

	const allowChapter51 = useMemo(
		() => !_.some(values.pricingType?.map((value) => !value.has_chapter51)),
		[values]
	);

	const allowClick = useMemo(
		() =>
			!_.some(
				values.pricingType?.map((value) => !value.has_clickability)
			),
		[values]
	);

	const onPricingTypeChanges = useCallback(
		(fieldName: string, fieldValues: CommodityFixings[]) => {
			setFieldValue(fieldName, fieldValues);
			if (_.some(fieldValues.map((value) => !value.has_chapter51))) {
				setFieldValue("chapter51Switch", false);
				setFieldValue("chapter51Type", "");
				setFieldValue("chapter51NumberOfHours", "");
			}
			if (_.some(fieldValues.map((value) => !value.has_clickability))) {
				setFieldValue("clickOptionSwitch", false);
			}
		},
		[setFieldValue]
	);

	return (
		<Grid container columnSpacing={4} rowSpacing={2}>
			<Grid item xs={12} display="flex" justifyContent="flex-start">
				<Typography
					variant="h6"
					component="h6"
					sx={style.titlePricingType}
				>
					Power pricing type
				</Typography>
			</Grid>

			<Grid item xs={12}>
				<PricingTemplateInput
					value={values.template}
					setFieldValue={setFieldValue}
				/>
			</Grid>
			<Grid item xs={12}>
				{Array.isArray(values.pricingType) && (
					<CheckboxesTags<CommodityFixings>
						label="Pricing type"
						trackBy="name"
						name="pricingType"
						value={values.pricingType}
						options={commodityFixingsList}
						setFieldValue={onPricingTypeChanges}
						error={!!errors.pricingType && touched.pricingType}
						helperText={
							!!errors.pricingType && touched.pricingType
								? errors.pricingType ||
								  "Please select at least one pricing type"
								: ""
						}
					/>
				)}
			</Grid>

			<Grid item xs={6}>
				<LocalizationProvider
					dateAdapter={AdapterDateFns}
					adapterLocale={en}
				>
					<CustomDatePicker
						format={"MM/yyyy"}
						name="startDate"
						sx={style.datePicker}
						views={["month", "year"]}
						onChange={(value) => {
							if (value) {
								const startDate = new Date(value as string);
								const endDate = endOfYear(startDate);
								setFieldValue("endDate", endDate);
							}
						}}
						slotProps={{
							textField: {
								size: "small",
								label: "Start date (month/year)",
								error:
									!!errors.startDate && !!touched.startDate,
								helperText:
									!!errors.startDate && touched.startDate
										? errors.startDate
										: "",
							},
						}}
					/>
				</LocalizationProvider>
			</Grid>
			<Grid item xs={6}>
				<LocalizationProvider
					dateAdapter={AdapterDateFns}
					adapterLocale={en}
				>
					<CustomDatePicker
						format={"MM/yyyy"}
						name="endDate"
						minDate={values.startDate}
						sx={style.datePicker}
						views={["month", "year"]}
						lastDayOfTheMonth
						slotProps={{
							textField: {
								size: "small",
								label: "End date (month/year)",
								error: !!errors.endDate && !!touched.endDate,
								helperText:
									!!errors.endDate && touched.endDate
										? errors.endDate
										: "",
							},
						}}
						onChange={(value) => {
							setFieldValue("gooEndDate", value);
						}}
					/>
				</LocalizationProvider>
			</Grid>

			<Grid item xs={12} display="flex" justifyContent="flex-start">
				<FormGroup sx={style.granularityCheckBox}>
					<FormLabel sx={{ display: "flex" }}>Granularity</FormLabel>
					<Box>
						<FormControlLabel
							name="granularityMonthly"
							control={
								<Checkbox
									onChange={handleChange}
									checked={values.granularityMonthly}
								/>
							}
							label={PRICING_GRANULARITY[0]}
						/>
						<FormControlLabel
							name="granularityQuarter"
							control={
								<Checkbox
									onChange={handleChange}
									checked={values.granularityQuarter}
								/>
							}
							label={PRICING_GRANULARITY[1]}
						/>
						<FormControlLabel
							name="granularityCal"
							control={
								<Checkbox
									onChange={handleChange}
									checked={values.granularityCal}
								/>
							}
							label={PRICING_GRANULARITY[2]}
						/>
						<FormControlLabel
							name="granularityWholeHorizon"
							control={
								<Checkbox
									onChange={handleChange}
									checked={values.granularityWholeHorizon}
								/>
							}
							label={PRICING_GRANULARITY[3]}
						/>
					</Box>
				</FormGroup>
			</Grid>
			<Grid item xs={6} display="flex" justifyContent="flex-start">
				<FormGroup sx={style.pricingDate}>
					<FormLabel sx={{ display: "flex" }}>Cross Date</FormLabel>
					<Box sx={{ marginTop: "10px" }}>
						<LocalizationProvider
							dateAdapter={AdapterDateFns}
							adapterLocale={en}
						>
							<CustomDatePicker
								disabled={values.live}
								name="pricingDate"
								sx={style.datePicker}
								maxDate={new Date()}
								slotProps={{
									textField: {
										size: "small",
										error:
											!!errors.pricingDate &&
											!!touched.pricingDate,
										helperText:
											!!errors.pricingDate &&
											touched.pricingDate
												? errors.pricingDate
												: "",
									},
								}}
							/>
						</LocalizationProvider>
					</Box>
				</FormGroup>
			</Grid>
			<Grid
				item
				xs={6}
				display="flex"
				flexDirection={"column"}
				justifyContent="flex-start"
			>
				<FormGroup sx={style.pricingDate}>
					<FormLabel sx={{ display: "flex" }}>
						Result options
					</FormLabel>
					<Box>
						<FormControlLabel
							name="alpha"
							disabled={resultOptionsDisabled}
							control={
								<Checkbox
									onChange={handleChange}
									checked={values.alpha}
								/>
							}
							label="Alpha"
						/>
						<FormControlLabel
							name="beta"
							disabled={resultOptionsDisabled}
							control={
								<Checkbox
									onChange={handleChange}
									checked={values.beta}
								/>
							}
							label="Beta"
						/>
						<FormControlLabel
							name="alphaAndBeta"
							disabled={resultOptionsDisabled}
							control={
								<Checkbox
									onChange={handleChange}
									checked={values.alphaAndBeta}
								/>
							}
							label="Alpha + Beta"
						/>
					</Box>
					{errors.alpha && (
						<FormHelperText sx={{ color: "red", fontSize: "16px" }}>
							{errors.alpha}
						</FormHelperText>
					)}
				</FormGroup>
			</Grid>
			<Grid item xs={10} display="flex" justifyContent="flex-start">
				<Typography variant="h6" component="h6" sx={style.titleOptions}>
					Parameters
				</Typography>
			</Grid>

			<Grid
				item
				xs={3}
				display="flex"
				rowSpacing={2}
				flexDirection={"column"}
				justifyContent="flex-start"
			>
				<FormGroup>
					<FormLabel sx={{ display: "flex", marginTop: "5px" }}>
						Base Options
					</FormLabel>
				</FormGroup>

				<CustomInput
					data-testid="base-capacity-volume"
					name="baseCapacity"
					type="number"
					disabled={baseProdDisabled}
					value={values.baseCapacity}
					onChange={handleChange}
					sx={{ width: "60%", marginTop: "5px" }}
					errorText={errors.baseCapacity}
					touched={touched.baseCapacity}
					placeholder="0"
					label="Base Capacity Volume (%P50)"
				></CustomInput>
				<Box sx={{ marginTop: "1em" }}>
					<FormGroup>
						<FormControlLabel
							control={
								<Switch
									name={"chapter51Switch"}
									checked={values.chapter51Switch}
									disabled={!allowChapter51}
									onChange={(event) => {
										handleChange(event);
										setFieldValue("chapter51Type", "");
										setFieldValue(
											"chapter51NumberOfHours",
											""
										);
									}}
								/>
							}
							label="Chapter51 Options"
						/>
					</FormGroup>

					<CustomSelect
						datatestId="chapter51Type"
						name="Chapter51 Type"
						label="Chapter51 Type"
						value={values.chapter51Type}
						disabled={!values.chapter51Switch}
						onChange={(event) =>
							setFieldValue("chapter51Type", event.target.value)
						}
						error={!!errors.chapter51Type}
						errorText={errors.chapter51Type}
						touched={!!touched.chapter51Type}
						sx={{
							width: "250px",
							background: "#fff",
							borderRadius: "0px",
						}}
						sxFC={{
							marginTop: "5px",
						}}
						items={CHAPTER51_TYPES.map((item) => {
							return {
								key: item,
								value: item,
							};
						})}
					></CustomSelect>
					<CustomSelect
						datatestId="chapter51NumberOfHours"
						name="Chapter51 Number Of Hours"
						label="Chapter51 Number Of Hours"
						value={values.chapter51NumberOfHours}
						disabled={!values.chapter51Switch}
						error={!!errors.chapter51NumberOfHours}
						errorText={errors.chapter51NumberOfHours}
						touched={!!touched.chapter51NumberOfHours}
						onChange={(event) =>
							setFieldValue(
								"chapter51NumberOfHours",
								event.target.value
							)
						}
						sxFC={{
							width: "250px",
							background: "#fff",
							borderRadius: "0px",
							marginTop: "1em",
						}}
						items={CHAPTER51_NUMBER_OF_HOURS.map((item) => {
							return {
								key: item,
								value: item,
							};
						})}
					></CustomSelect>
				</Box>
			</Grid>
			<Grid
				item
				xs={3}
				display="flex"
				flexDirection={"column"}
				justifyContent="flex-start"
			>
				<FormGroup>
					<FormControlLabel
						control={
							<Switch
								name={"hasNegativePrice"}
								checked={values.hasNegativePrice}
								onChange={handleChange}
							/>
						}
						label="Negative price"
					/>
				</FormGroup>
				<FormControl>
					<CustomInput
						name="negativePriceValue"
						disabled={!values.hasNegativePrice}
						sx={style.adjustmentPower}
						onChange={handleChange}
						variant="standard"
						type="number"
						value={values.negativePriceValue}
						error={!!errors.negativePriceValue}
						errorText={errors.negativePriceValue}
						touched={true}
					/>
				</FormControl>
			</Grid>
			<Grid
				item
				xs={6}
				display="flex"
				flexDirection={"column"}
				justifyContent="flex-start"
			>
				<FormGroup>
					<FormControlLabel
						control={
							<Switch
								name={"ppaWithGooSwitch"}
								checked={values.ppaWithGooSwitch}
								onChange={handleChange}
							/>
						}
						label="Add GOO"
					/>
				</FormGroup>
				<FormControl>
					<FormLabel>GOO adjustment on power</FormLabel>
					<CustomInput
						name="gooPowerAdjustment"
						placeholder="%"
						disabled={!values.ppaWithGooSwitch}
						sx={style.adjustmentPower}
						onChange={handleChange}
						variant="standard"
						type="number"
						value={values.gooPowerAdjustment}
						error={!!errors.gooPowerAdjustment}
						errorText={errors.gooPowerAdjustment}
						touched={true}
					/>
				</FormControl>

				<FormGroup>
					<FormControlLabel
						control={
							<Switch
								name={"clickOptionSwitch"}
								disabled={!allowClick}
								checked={values.clickOptionSwitch}
								onChange={handleChange}
							/>
						}
						label="Clickability Options"
					/>
				</FormGroup>
				<FormControl error={true}>
					<FormGroup sx={style.alignedProupe}>
						{CLICK_GRANULARITY.map((granularity) => (
							<FormControlLabel
								key={`checkbox-${granularity}`}
								value={granularity}
								name="clickGranularity"
								onClick={() =>
									setFieldValue(`clickCount${granularity}`, 1)
								}
								control={
									<Checkbox
										onChange={handleChange}
										disabled={!values.clickOptionSwitch}
										checked={values.clickGranularity?.includes(
											granularity as ClickabilityGranularity
										)}
									/>
								}
								label={
									granularity == "NoSplit" ? (
										<>
											<Box
												sx={{
													display: "flex",
													marginTop: "6px",
												}}
											>
												No split
												<Typography title="NoSplit means non-standard date, e.g, from June to June.">
													<HelpOutlineIcon
														sx={{
															marginLeft: "5px",
														}}
													/>
												</Typography>
											</Box>
										</>
									) : (
										granularity
									)
								}
							/>
						))}

						{!!errors.clickGranularity &&
							!!touched.clickGranularity && (
								<FormHelperText>
									{errors.clickGranularity}
								</FormHelperText>
							)}
					</FormGroup>
				</FormControl>
				{Array.isArray(values.clickGranularity) &&
					values.clickGranularity.length > 0 &&
					values.clickGranularity.map((granularity: string) => (
						<CustomInput
							key={granularity}
							id={`clickCount${granularity}`}
							name={`clickCount${granularity}`}
							type="number"
							value={
								values[
									("clickCount" +
										granularity) as PricingRequestDataKeys
								]
							}
							onChange={handleChange}
							sx={style.clickCount}
							placeholder="1"
							label={"Number of clicks per " + granularity}
							disabled={!values.clickOptionSwitch}
							errorText={
								errors[
									`clickCount${granularity}` as PricingRequestDataKeys
								]
							}
							touched={
								touched[
									`clickCount${granularity}` as PricingRequestDataKeys
								]
							}
							InputProps={{
								startAdornment: (
									<IconButton
										disabled={!values.clickOptionSwitch}
										onClick={() => {
											setFieldValue(
												"clickCount" + granularity,
												Number(
													values[
														("clickCount" +
															granularity) as PricingRequestDataKeys
													]
												) > 1
													? Number(
															values[
																("clickCount" +
																	granularity) as PricingRequestDataKeys
															]
													  ) - 1
													: 1
											);
											return (
												document.getElementById(
													`clickCount${granularity}`
												) as any
											).stepDown();
										}}
									>
										<RemoveIcon />
									</IconButton>
								),
								endAdornment: (
									<IconButton
										disabled={!values.clickOptionSwitch}
										onClick={() => {
											setFieldValue(
												"clickCount" + granularity,
												Number(
													values[
														("clickCount" +
															granularity) as PricingRequestDataKeys
													]
												) + 1
											);
											return (
												document.getElementById(
													"clickCount" + granularity
												) as any
											).stepUp();
										}}
									>
										<AddIcon />
									</IconButton>
								),
							}}
						></CustomInput>
					))}
			</Grid>
		</Grid>
	);
}

export default PricingStep;
