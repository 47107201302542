import HorizonUnit from "./HorizonUnit";
import { Formik } from "formik";
import { If } from "../../../../common/components/If";

export interface HorizonUnitBase {
	old_value: string;
	new_value: string;
	isDiscarded?: boolean;
}

export interface HorizonUnitHOCProps<T extends HorizonUnitBase> {
	onUpdate: (data: Partial<T>) => void;
	name: string;
	initialValues: T;
}

function HorizonUnitHOC<T extends HorizonUnitBase>({
	onUpdate,
	initialValues,
	name,
}: HorizonUnitHOCProps<T>) {
	return (
		<If condition={initialValues}>
			<Formik
				enableReinitialize={true}
				initialValues={{
					old_value: initialValues.old_value,
					new_value: initialValues.new_value,
					is_checked: !initialValues?.isDiscarded,
				}}
				onSubmit={({
					old_value,
					is_checked,
					new_value,
				}: {
					old_value: string;
					new_value: string;
					is_checked: boolean;
				}) => {
					onUpdate({
						...initialValues,
						old_value,
						new_value,
						isDiscarded: !is_checked,
					});
				}}
			>
				{({
					handleChange,
					handleSubmit,
					values,
					errors,
					setFieldValue,
					submitForm,
				}) => (
					<form onSubmit={handleSubmit}>
						<HorizonUnit
							name={name}
							oldValue={values.old_value}
							newValue={values.new_value}
							isChecked={values.is_checked}
							oldValueErrorMessage={errors.old_value}
							newValueErrorMessage={errors.new_value}
							onChangeOldValue={(event) => {
								handleChange(event);
								submitForm();
							}}
							onChangeNewValue={(event) => {
								handleChange(event);
								submitForm();
							}}
							onChangeIsChecked={() => {
								setFieldValue("is_checked", !values.is_checked);
								submitForm();
							}}
						/>
					</form>
				)}
			</Formik>
		</If>
	);
}

export default HorizonUnitHOC;
