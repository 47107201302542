export enum Groups {
	admin = "gepo-admin",
	originator = "gepo-originator",
	trader = "gepo-trader",
	geco_originator = "geco-originator",
	geco_ops = "geco-ops",
	reader = "gepo-reader",
	geco_admin = "geco-admin",
	geco_trader = "geco-trader",
}

export type GroupsList = keyof typeof Groups | Groups;
