import {
	Card,
	CardContent,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormHelperText,
	FormLabel,
	Grid,
	IconButton,
	SelectChangeEvent,
	Switch,
	SxProps,
	Theme,
	Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import en from "date-fns/locale/en-GB";
import CustomDatePicker from "../../../common/components/CustomDatePicker";
import CustomInput from "../../../common/components/CustomInput";
import {
	CLICK_GRANULARITY,
	FinancialType,
	LEG_TYPE,
	PRICING_GRANULARITY,
	STRIKE_AVERAGE_TYPE,
} from "../PricingRequestStepper.constant";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { lightGrey } from "../../../core/theme";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectCommodityFixingsState } from "../PricingRequestStepper.selector";
import { Portfolio, Site } from "../../sites/sites.module";
import { endOfYear } from "date-fns";
import CustomSelect from "../../../common/components/CustomSelect";
import { ClickabilityGranularity } from "../pricingRequestStepper.schema";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
	PricingRequestData,
	PricingRequestDataKeys,
} from "../pricingRequestStepper.module";
import PricingTemplateInput from "./PricingTemplateSelect";
import { CommodityFixings } from "../PricingRequestStepper.slice";
import * as _ from "lodash";
import {
	isFinancialClickPricing,
	isFinancialDAPricing,
} from "../utils/helpers.utils";

const style: { [key: string]: SxProps<Theme> } = {
	container: {
		"& legend, & label": {
			fontSize: "14px",
		},
	},
	cardTitle: {
		color: "",
	},
	alignedProupe: {
		flexDirection: "row",
		textTransform: "capitalize",
		marginBottom: "5px",
	},
	titlePricingType: {
		marginBottom: "32px",
		fontWeight: 700,
	},
	datePicker: {
		width: "100%",
	},
	adjustmentPower: {
		width: "200px",
		"& p": {
			backgroundColor: lightGrey,
		},
	},
	granularityCheckBox: {
		flexDirection: "column",
		textTransform: "capitalize",
		marginTop: "16px",
	},
	clickCount: {
		width: "180px",
		marginBottom: "1em",
		"& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
			{
				WebkitAppearance: "none",
				margin: 0,
			},
		"& input": {
			textAlign: "center",
		},
		"& input::placeholder": {
			textAlign: "center",
		},
		"& svg": {
			color: "grey",
		},
	},
	pricingDate: {
		width: "100%",
	},
};

interface PricingFinancialStepProps {
	values: PricingRequestData;
	handleChange: any;
	setFieldValue: (fieldName: string, value: any) => void;
	setFieldError: (fieldName: string, value: any) => void;
	errors: any;
	touched: any;
	sitesValues: (Site | Portfolio)[];
	setLoading: (value: boolean) => void;
}

function PricingFinancialStep(props: PricingFinancialStepProps) {
	const { values, handleChange, setFieldValue, errors, touched } = props;
	const pricingTypes = useSelector(selectCommodityFixingsState);
	useEffect(() => {
		setFieldValue("clickGranularity", [CLICK_GRANULARITY[0]]);
		if (!_.isEmpty(pricingTypes) && !values.strikePricingType) {
			setFieldValue("strikePricingType", pricingTypes[0]);
			setFieldValue(
				"commodityPricingType",
				pricingTypes.find((p) =>
					isFinancialDAPricing(p.name as FinancialType)
				)
			);
		}
		setFieldValue("alpha", true);
		setFieldValue("beta", true);
		setFieldValue("alphaAndBeta", true);
	}, [pricingTypes]);

	return (
		<Grid container sx={style.container} columnSpacing={4} rowSpacing={2}>
			<Grid item xs={12} display="flex" justifyContent="flex-start">
				<Typography
					variant="h6"
					component="h6"
					sx={style.titlePricingType}
				>
					Financial pricing type
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<PricingTemplateInput
					value={values.template}
					setFieldValue={setFieldValue}
				/>
			</Grid>
			<Grid item xs={6} display="flex" justifyContent="flex-start">
				<Card sx={{ minWidth: "100%" }}>
					<CardContent>
						<Typography variant="h3" component="div">
							Commodity price
						</Typography>
						<FormControl fullWidth={true}>
							<FormLabel component="legend">
								Pricing type
							</FormLabel>
							<CustomSelect
								name="commodityPricingType"
								data-testid="commodity-pricing-type"
								value={values.commodityPricingType?.name || ""}
								disabled={true}
								items={pricingTypes.map(
									(element: CommodityFixings) => ({
										key: element.name,
										value: element.name,
									})
								)}
								onChange={() => {}}
								touched={touched.commodityPricingType}
								error={!!errors.commodityPricingType}
								errorText={
									!!errors.commodityPricingType &&
									touched.commodityPricingType
										? errors.commodityPricingType
										: ""
								}
							/>
						</FormControl>
						<Grid
							item
							xs={12}
							display="flex"
							justifyContent="flex-start"
						>
							<Grid
								item
								xs={6}
								display="flex"
								justifyContent="flex-start"
							>
								<FormControl>
									<FormLabel>Alpha</FormLabel>
									<CustomInput
										name="commodityAlpha"
										placeholder="0"
										onChange={handleChange}
										variant="standard"
										type="number"
										value={values.commodityAlpha}
										error={!!errors.commodityAlpha}
										errorText={errors.commodityAlpha}
										touched={true}
									/>
								</FormControl>
							</Grid>
							<Grid
								item
								xs={6}
								display="flex"
								justifyContent="flex-start"
							>
								<FormControl>
									<FormLabel>Beta</FormLabel>
									<CustomInput
										name="commodityBeta"
										placeholder="0"
										onChange={handleChange}
										variant="standard"
										type="number"
										value={values.commodityBeta}
										error={!!errors.commodityBeta}
										errorText={errors.commodityBeta}
										touched={true}
									/>
								</FormControl>
							</Grid>
						</Grid>
						<FormGroup>
							<FormControlLabel
								control={
									<Switch
										name={"commodityHasNegativePrice"}
										checked={
											values.commodityHasNegativePrice
										}
										onChange={handleChange}
									/>
								}
								label="Condition Negative Price"
							/>
						</FormGroup>
					</CardContent>
				</Card>
			</Grid>
			<Grid item xs={6} display="flex" justifyContent="flex-start">
				<Card sx={{ minWidth: "100%" }}>
					<CardContent>
						<Typography variant="h3" component="div">
							Strike price
						</Typography>

						<FormControl fullWidth={true}>
							<FormLabel component="legend">
								Pricing type
							</FormLabel>
							<CustomSelect
								data-testid="strike-pricing-type"
								name="strikePricingType"
								value={values.strikePricingType?.name || ""}
								items={
									pricingTypes.map((element) => ({
										key: element?.name,
										value: element?.name,
									})) as any
								}
								onChange={(event: any) => {
									setFieldValue(
										event.target.name,
										!_.isEmpty(pricingTypes)
											? pricingTypes.find(
													(el) =>
														el.name ===
														event.target.value
											  )
											: undefined
									);
								}}
								touched={touched.strikePricingType}
								error={!!errors.strikePricingType}
								errorText={
									!!errors.strikePricingType &&
									touched.strikePricingType
										? errors.strikePricingType
										: ""
								}
							/>
						</FormControl>
						{isFinancialDAPricing(
							values.strikePricingType?.name as FinancialType
						) && (
							<FormControl fullWidth={true}>
								<FormLabel component="legend">
									Average type
								</FormLabel>
								<CustomSelect
									data-testid="strike-average-type"
									name="averageType"
									value={values.averageType || ""}
									items={STRIKE_AVERAGE_TYPE}
									onChange={(event: any) => {
										setFieldValue(
											event.target.name,
											event.target.value
										);
									}}
									error={
										!!errors.averageType &&
										touched.averageType
									}
									errorText={
										!!errors.averageType &&
										touched.averageType
											? errors.averageType
											: ""
									}
								/>
							</FormControl>
						)}

						<FormGroup>
							<FormControlLabel
								control={
									<Switch
										name={"strikeHasNegativePrice"}
										checked={values.strikeHasNegativePrice}
										onChange={handleChange}
									/>
								}
								label="Condition Negative Price"
							/>
						</FormGroup>
					</CardContent>
				</Card>
			</Grid>

			<Grid item xs={12}>
				<Card sx={{ minWidth: "100%" }}>
					<CardContent>
						<Grid container columnSpacing={4}>
							<Grid item xs={6}>
								<FormControl fullWidth={true}>
									<FormLabel component="legend">
										Start date
									</FormLabel>
									<LocalizationProvider
										dateAdapter={AdapterDateFns}
										adapterLocale={en}
									>
										<CustomDatePicker
											format={"MM/yyyy"}
											name="startDate"
											sx={style.datePicker}
											views={["month", "year"]}
											onChange={(value) => {
												if (value) {
													const startDate = new Date(
														value as string
													);
													const endDate =
														endOfYear(startDate);
													setFieldValue(
														"endDate",
														endDate
													);
												}
											}}
											slotProps={{
												textField: {
													size: "small",
													error:
														!!errors.startDate &&
														!!touched.startDate,
													helperText:
														!!errors.startDate &&
														touched.startDate
															? errors.startDate
															: "",
												},
											}}
										/>
									</LocalizationProvider>
								</FormControl>
							</Grid>
							<Grid item xs={6}>
								<FormControl fullWidth={true}>
									<FormLabel component="legend">
										End date
									</FormLabel>
									<LocalizationProvider
										dateAdapter={AdapterDateFns}
										adapterLocale={en}
									>
										<CustomDatePicker
											format={"MM/yyyy"}
											name="endDate"
											minDate={values.startDate}
											sx={style.datePicker}
											views={["month", "year"]}
											lastDayOfTheMonth
											slotProps={{
												textField: {
													size: "small",
													error:
														!!errors.endDate &&
														!!touched.endDate,
													helperText:
														!!errors.endDate &&
														touched.endDate
															? errors.endDate
															: "",
												},
											}}
										/>
									</LocalizationProvider>
								</FormControl>
							</Grid>
						</Grid>
						<Grid
							item
							xs={6}
							display="flex"
							justifyContent="flex-start"
						>
							<FormGroup sx={style.granularityCheckBox}>
								<FormLabel sx={{ display: "flex" }}>
									Granularity
								</FormLabel>
								<Box>
									<FormControlLabel
										name="granularityMonthly"
										control={
											<Checkbox
												onChange={handleChange}
												checked={
													values.granularityMonthly
												}
											/>
										}
										label={PRICING_GRANULARITY[0]}
									/>
									<FormControlLabel
										name="granularityQuarter"
										control={
											<Checkbox
												onChange={handleChange}
												checked={
													values.granularityQuarter
												}
											/>
										}
										label={PRICING_GRANULARITY[1]}
									/>
									<FormControlLabel
										name="granularityCal"
										control={
											<Checkbox
												onChange={handleChange}
												checked={values.granularityCal}
											/>
										}
										label={PRICING_GRANULARITY[2]}
									/>
									<FormControlLabel
										name="granularityWholeHorizon"
										control={
											<Checkbox
												onChange={handleChange}
												checked={
													values.granularityWholeHorizon
												}
											/>
										}
										label={PRICING_GRANULARITY[3]}
									/>
								</Box>
							</FormGroup>
						</Grid>
					</CardContent>
				</Card>
			</Grid>

			<Grid item xs={12}>
				<Card sx={{ minWidth: "100%" }}>
					<CardContent
						sx={{ display: "flex", flexDirection: "column" }}
					>
						<Typography variant="h3" component="div">
							Parameters
						</Typography>
						<Box display={"flex"} flexDirection={"row"}>
							<Box flexGrow={1}>
								<FormGroup>
									<FormControlLabel
										control={
											<Switch
												data-testid={"base-options"}
												name={"hasBaseOptions"}
												checked={values.hasBaseOptions}
												// For now, it is not used in financial PPA/CPPA
												disabled={true}
												onChange={handleChange}
											/>
										}
										label="Base options"
									/>
								</FormGroup>
								<FormControl fullWidth={true}>
									<FormLabel component="legend">
										Base Capacity volume (%P50)
									</FormLabel>
									<CustomInput
										key={"baseCapacity"}
										id={`baseCapacity`}
										name={`baseCapacity`}
										type="number"
										value={values.baseCapacity}
										onChange={handleChange}
										sx={style.clickCount}
										placeholder="1"
										disabled={!values.hasBaseOptions}
										errorText={errors.baseCapacity}
										touched={touched.baseCapacity}
										InputProps={{
											startAdornment: (
												<IconButton
													data-testid={
														"base-capacity-volume-plus"
													}
													disabled={
														!values.hasBaseOptions
													}
													onClick={() => {
														setFieldValue(
															"baseCapacity",
															Number(
																values.baseCapacity
															) > 0
																? Number(
																		values.baseCapacity
																  ) - 1
																: 1
														);
														return (
															document.getElementById(
																`baseCapacity`
															) as any
														).stepDown();
													}}
												>
													<RemoveIcon />
												</IconButton>
											),
											endAdornment: (
												<IconButton
													disabled={
														!values.hasBaseOptions
													}
													onClick={() => {
														setFieldValue(
															"baseCapacity",
															Number(
																values.baseCapacity
															) + 1
														);
														return (
															document.getElementById(
																"baseCapacity"
															) as any
														).stepUp();
													}}
												>
													<AddIcon />
												</IconButton>
											),
										}}
									></CustomInput>
								</FormControl>
							</Box>
							{/* <Box flexGrow={1} display={"flex"} justifyContent={"center"}> */}
							<Box
								flexGrow={1}
								display={"flex"}
								alignItems={"center"}
							>
								<FormControl fullWidth={true}>
									<FormLabel component="legend">
										Leg Type
									</FormLabel>
									<CustomSelect
										name="legType"
										value={values.legType || ""}
										items={LEG_TYPE}
										onChange={(
											event: SelectChangeEvent<unknown>
										) => {
											setFieldValue(
												event.target.name,
												event.target.value
											);
										}}
										error={
											!!errors.legType && touched.legType
										}
										errorText={
											!!errors.legType && touched.legType
												? errors.legType
												: ""
										}
									/>
								</FormControl>
							</Box>
						</Box>

						{isFinancialClickPricing(
							values.strikePricingType?.name as FinancialType
						) && (
							<>
								<FormGroup>
									<FormControlLabel
										control={
											<Switch
												name={"clickOptionSwitch"}
												checked={
													values.clickOptionSwitch
												}
												onChange={handleChange}
											/>
										}
										label="Clickability Options"
									/>
								</FormGroup>
								<FormControl error={true}>
									<FormGroup sx={style.alignedProupe}>
										{CLICK_GRANULARITY.map(
											(granularity) => (
												<FormControlLabel
													key={`checkbox-${granularity}`}
													value={granularity}
													name="clickGranularity"
													onClick={() =>
														setFieldValue(
															`clickCount${granularity}`,
															1
														)
													}
													control={
														<Checkbox
															onChange={
																handleChange
															}
															disabled={
																!values.clickOptionSwitch
															}
															checked={values.clickGranularity?.includes(
																granularity as ClickabilityGranularity
															)}
														/>
													}
													label={
														granularity ==
														"NoSplit" ? (
															<>
																<Box
																	sx={{
																		display:
																			"flex",
																		marginTop:
																			"6px",
																	}}
																>
																	No split
																	<Typography title="NoSplit means non-standard date, e.g, from June to June.">
																		<HelpOutlineIcon
																			sx={{
																				marginLeft:
																					"5px",
																			}}
																		/>
																	</Typography>
																</Box>
															</>
														) : (
															granularity
														)
													}
												/>
											)
										)}

										{!!errors.clickGranularity &&
											!!touched.clickGranularity && (
												<FormHelperText>
													{errors.clickGranularity}
												</FormHelperText>
											)}
									</FormGroup>
								</FormControl>
								{Array.isArray(values.clickGranularity) &&
									values.clickGranularity.length > 0 &&
									values.clickGranularity.map(
										(granularity: string) => (
											<FormGroup key={granularity}>
												<CustomInput
													key={granularity}
													id={`clickCount${granularity}`}
													name={`clickCount${granularity}`}
													type="number"
													value={
														values[
															("clickCount" +
																granularity) as PricingRequestDataKeys
														]
													}
													onChange={handleChange}
													sx={style.clickCount}
													placeholder="1"
													label={
														"Number of clicks per " +
														granularity
													}
													disabled={
														!values.clickOptionSwitch
													}
													errorText={
														errors[
															`clickCount${granularity}` as PricingRequestDataKeys
														]
													}
													touched={
														touched[
															`clickCount${granularity}` as PricingRequestDataKeys
														]
													}
													InputProps={{
														startAdornment: (
															<IconButton
																disabled={
																	!values.clickOptionSwitch
																}
																onClick={() => {
																	setFieldValue(
																		"clickCount" +
																			granularity,
																		Number(
																			values[
																				("clickCount" +
																					granularity) as PricingRequestDataKeys
																			]
																		) > 1
																			? Number(
																					values[
																						("clickCount" +
																							granularity) as PricingRequestDataKeys
																					]
																			  ) -
																					1
																			: 1
																	);
																	return (
																		document.getElementById(
																			`clickCount${granularity}`
																		) as any
																	).stepDown();
																}}
															>
																<RemoveIcon />
															</IconButton>
														),
														endAdornment: (
															<IconButton
																disabled={
																	!values.clickOptionSwitch
																}
																onClick={() => {
																	setFieldValue(
																		"clickCount" +
																			granularity,
																		Number(
																			values[
																				("clickCount" +
																					granularity) as PricingRequestDataKeys
																			]
																		) + 1
																	);
																	return (
																		document.getElementById(
																			"clickCount" +
																				granularity
																		) as any
																	).stepUp();
																}}
															>
																<AddIcon />
															</IconButton>
														),
													}}
												></CustomInput>
											</FormGroup>
										)
									)}
							</>
						)}
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
}

export default PricingFinancialStep;
