import { Pricing } from "../pricingListSlice";

export const getPricingType = (pricing?: Pricing) => {
	if (!pricing) return "-";
	if (pricing.is_financial) {
		return pricing.aggregated_pricing_type_name;
	}
	return (
		pricing?.pricing_type?.geco_name ||
		pricing?.pricing_type?.long_name ||
		pricing?.pricing_type?.name
	);
};
