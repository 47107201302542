import {
	ToggleButtonGroup,
	ToggleButton,
	Paper,
	Box,
	Typography,
} from "@mui/material";

import { toggleButtonGroupClasses } from "@mui/material/ToggleButtonGroup";
import { styled } from "@mui/material/styles";
import { secondaryColor } from "../../../core/theme";
import { Dispatch, SetStateAction } from "react";

interface ViewToggleProps {
	listView: Array<{ label: string; value: string }>;
	activeView: string;
	title: string;
	setActiveView: Dispatch<SetStateAction<string>>;
	isDisabled?: boolean;
}

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }: any) => ({
	[`& .${toggleButtonGroupClasses.grouped}`]: {
		margin: theme.spacing(0.5),
		padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
		border: 0,
		backgroundColor: secondaryColor,
		[`&.Mui-selected`]: {
			backgroundColor: "white",
		},
	},
}));

export default function Toggle(props: ViewToggleProps) {
	const {
		title,
		activeView,
		setActiveView,
		listView,
		isDisabled = false,
	} = props;
	return (
		<Box
			sx={{
				display: "flex",
				gap: "1rem",
				alignItems: "center",
			}}
		>
			<Typography fontWeight={700}>{title}</Typography>
			<Paper
				elevation={0}
				sx={(theme) => ({
					display: "flex",
					border: `1px solid ${theme.palette.divider}`,
					flexWrap: "wrap",
					backgroundColor: secondaryColor,
				})}
			>
				<StyledToggleButtonGroup
					size="small"
					value={activeView}
					exclusive
					onChange={(_, choice) => {
						if (activeView !== choice) setActiveView(choice);
					}}
					aria-label="text alignment"
				>
					{listView.map(({ label, value }) => (
						<ToggleButton
							key={value}
							value={value}
							aria-label="List View"
							data-testid={`viewBy${value}`}
							sx={{
								borderRadius: 0,
								textTransform: "none",
								fontSize: "14px",
								fontWeight: "700",
							}}
							disabled={isDisabled}
						>
							{label}
						</ToggleButton>
					))}
				</StyledToggleButtonGroup>
			</Paper>
		</Box>
	);
}
