export function shouldDiplayAlphAndBeta(
	pricingOption: string,
	fieldName?: string
) {
	if (
		fieldName &&
		[
			"BetaClient",
			"AlphaForAlphaAndBetaClient",
			"BetaForAlphaAndBetaClient",
		].includes(fieldName) &&
		pricingOption === "alpha"
	) {
		return "none";
	}
	if (
		fieldName &&
		[
			"AlphaIndexClient",
			"AlphaForAlphaAndBetaClient",
			"BetaForAlphaAndBetaClient",
		].includes(fieldName) &&
		pricingOption === "beta"
	) {
		return "none";
	}
	if (
		fieldName &&
		["BetaClient", "AlphaIndexClient"].includes(fieldName) &&
		pricingOption === "alpha_and_beta"
	) {
		return "none";
	}
	if (
		fieldName &&
		[
			"AlphaIndexClient",
			"BetaClient",
			"AlphaForAlphaAndBetaClient",
			"BetaForAlphaAndBetaClient",
		].includes(fieldName) &&
		!pricingOption
	) {
		return "none";
	}
	return "flex";
}
