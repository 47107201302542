import { white } from "../../../../core/theme";

export const style = {
	buttonWrapper: {
		display: "flex",
		justifyContent: "space-between",
	},
	buttonSpace: {
		display: "flex",
		gap: 4,
	},
	container: {
		minWidth: 600,
		clickability: {
			backgroundColor: white,
			display: "flex",
			edit: {
				marginLeft: "auto",
				alignSelf: "center",
				marginRight: "16px",
			},
		},
	},
};
