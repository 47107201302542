import { createSlice } from "@reduxjs/toolkit";

export interface CommodityFixings {
	id: number;
	name: string;
	geco_name?: string;
	external_id: number;
	long_name: string;
	commodity: string;
	market_data_code: string;
	label?: string;
	has_chapter51?: boolean;
	has_clickability?: boolean;
	send_constant_click?: boolean;
	direction?: string;
	is_baseprod?: boolean;
}
export interface PricingTemplate {
	id: number;
	title: string;
	params: object;
	label?: string;
}
export interface PricingRequestForm {
	loader: {
		commodityFixings: boolean;
		templates: boolean;
		addNewTemplate: boolean;
	};
	errors: {
		commodityFixings?: boolean;
		createPricingRequest?: string;
		templates?: boolean;
		addNewTemplate?: string;
	};
	success: {
		addTemplate?: boolean;
	};
	commodityFixings: CommodityFixings[];
	templates: PricingTemplate[];
}

const initialState: PricingRequestForm = {
	loader: {
		commodityFixings: false,
		templates: false,
		addNewTemplate: false,
	},
	success: {
		addTemplate: false,
	},
	errors: {},
	commodityFixings: [],
	templates: [],
};

export const pricingRequestFormSlice = createSlice({
	name: "pricingRequestForm",
	initialState,
	reducers: {
		getCommodityFixingsSuccess: (state, action) => {
			state.commodityFixings = action.payload;
			state.loader.commodityFixings = false;
		},
		getCommodityFixingsError: (state, action) => {
			state.errors.commodityFixings = action.payload;
			state.loader.commodityFixings = false;
		},
		createPricingRequestSuccess: (state) => {
			state.errors.createPricingRequest = "";
		},
		createPricingRequestError: (state, action) => {
			state.errors.createPricingRequest = action.payload;
		},
		resetPricingRequestResult: (state) => {
			state.errors.createPricingRequest = "";
		},
		getPricingTemplatesSuccess: (state, action) => {
			state.templates = action.payload;
			state.loader.templates = false;
		},
		getPricingTemplateError: (state, action) => {
			state.errors.templates = action.payload;
			state.loader.templates = false;
		},
		enablePricingTemplatesLoader: (state) => {
			state.loader.addNewTemplate = true;
		},
		addNewPricingTemplates: (state, action) => {
			state.templates = [...state.templates, action.payload];
			state.errors.addNewTemplate = "";
			state.loader.addNewTemplate = false;
			state.success.addTemplate = true;
		},
		addNewPricingTemplatesErrors: (state, action) => {
			state.errors.addNewTemplate = action.payload;
			state.loader.addNewTemplate = false;
			state.success.addTemplate = false;
		},
	},
});

export const {
	getCommodityFixingsSuccess,
	getCommodityFixingsError,
	createPricingRequestSuccess,
	resetPricingRequestResult,
	createPricingRequestError,
	getPricingTemplatesSuccess,
	getPricingTemplateError,
	addNewPricingTemplates,
	addNewPricingTemplatesErrors,
	enablePricingTemplatesLoader,
} = pricingRequestFormSlice.actions;

export default pricingRequestFormSlice.reducer;
