import React from "react";
import {
	Box,
	DialogActions,
	DialogContent,
	IconButton,
	Typography,
	Dialog,
	DialogTitle,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PieChart from "@mui/icons-material/PieChart";
import {
	NJCard,
	NJCardBody,
	NJButton,
} from "@engie-group/fluid-design-system-react";
import { format } from "date-fns";
import { useParams } from "react-router";

import PieChartClicks from "./PieChart";
import BarsChartClicks from "./BarsChart";
import {
	isDateRangeInAnyQuarter,
	mappingData,
	totalVolumePerYear,
} from "./utils";
import FluidSelect from "../../../../../common/components/FluidSelect";
import SimpleTable from "../../../../../common/components/SimpleTable";
import Toggle from "../../../../../common/components/Toggle/Toggle";

import type { NewClickOverviewT, MappedClicksDataT, ViewByT } from "./types";
import type { ClickTypesResponseType } from "../../../../../requests_cm/gecoReferentialService/types";
import type {
	ContractPeriodClickObjectType,
	ContractPeriodType,
} from "../../../../../requests_cm/gecoContractsService/types";

interface OverviewModalProps {
	contractPeriod: ContractPeriodType;
	dataClicksRaw: Array<ContractPeriodClickObjectType>;
	dataPeriodList: Array<ClickTypesResponseType>;
	handleNewClick: (newClick: NewClickOverviewT) => void;
	isOpen: boolean;
	handleClose?: () => void;
}

export default function OverviewModal(props: OverviewModalProps) {
	const {
		dataPeriodList,
		dataClicksRaw,
		isOpen,
		handleClose,
		handleNewClick,
		contractPeriod,
	} = props;
	const { contractPeriodId } = useParams();
	const [viewBy, setViewBy] = React.useState<ViewByT>("month");
	const sortedByDate = React.useMemo(
		() => mappingData(dataClicksRaw, dataPeriodList, contractPeriod),
		[dataClicksRaw, dataPeriodList, contractPeriod]
	);
	const yearsSelect = Object.keys(sortedByDate);
	const [selectedYear, setSelectedYear] = React.useState<string>(
		yearsSelect[0].toString()
	);
	const containFullQuarter = React.useMemo(
		() =>
			isDateRangeInAnyQuarter(
				new Date(contractPeriod.start_date),
				new Date(contractPeriod.end_date)
			),
		[contractPeriod]
	);
	const totalVolume = React.useMemo(
		() => totalVolumePerYear(sortedByDate[selectedYear].month),
		[sortedByDate, selectedYear]
	);
	React.useEffect(() => {
		//reset view when the contract period changed
		setViewBy("month");
	}, [contractPeriodId]);

	return (
		<Dialog maxWidth="xl" onClose={handleClose} open={isOpen}>
			<DialogTitle
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<Box>
					<IconButton color="primary">
						<PieChart />
					</IconButton>
					Clickability overview
				</Box>
				<Box>
					<IconButton aria-label="close" onClick={handleClose}>
						<CloseIcon />
					</IconButton>
				</Box>
			</DialogTitle>
			<DialogContent>
				<Box
					sx={{
						display: "flex",
						justifyContent: "flex-end",
						marginBottom: "1rem",
					}}
				>
					<FluidSelect
						isLabelStatic
						items={yearsSelect.map((year) => ({
							label: year,
							value: year,
						}))}
						label={"Select year"}
						name={"clicks_year"}
						onChange={(year) => setSelectedYear(String(year))}
						value={selectedYear}
					/>
				</Box>
				<Box
					sx={{
						border: "1px solid #EBEEF1",
						padding: "1rem",
						marginBottom: "1rem",
					}}
				>
					<Typography
						fontSize={"1.2rem"}
						fontWeight={700}
						marginBottom={2}
					>
						Clicked volume availability
					</Typography>
					<Box
						sx={{
							display: "grid",
							gridTemplateColumns: "3fr 5fr 2fr",
							gap: 3,
						}}
					>
						<PieChartClicks
							data={{
								clicked: {
									volume: totalVolume.totalVolumePercentage,
									volume_mwh: totalVolume.totalVolumeAbsolute,
								},
								available: {
									volume: totalVolume.availableVolumePercentage,
									volume_mwh:
										totalVolume.availableVolumeAbsolute,
								},
							}}
						/>
						<SimpleTable
							dataTestid="clickabilityTable"
							headers={[
								{ label: "Type", accessor: "type" },
								{ label: "Volume (%)", accessor: "volume" },
								{
									label: "Volume (Mwh)",
									accessor: "volume_mwh",
								},
							]}
							items={[
								{
									type: "Clicked",
									volume: totalVolume.totalVolumePercentage,
									volume_mwh: totalVolume.totalVolumeAbsolute,
								},
								{
									type: "Free/Available",
									volume: totalVolume.availableVolumePercentage,
									volume_mwh:
										totalVolume.availableVolumeAbsolute,
								},
							]}
						/>
						<NJCard
							hasBottomBorder
							className=""
							data-testid="totalVolumeAbsolute"
						>
							<NJCardBody
								title={`${totalVolume.totalVolumeAbsolute} MWh`}
								data-testid="totalVolumeAbsoluteValue"
							>
								<p>Total volume to click</p>
							</NJCardBody>
						</NJCard>
					</Box>
				</Box>
				<Box
					sx={{
						border: "1px solid #EBEEF1",
						padding: "1rem",
					}}
				>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
						}}
					>
						<Typography fontSize={"1.2rem"} fontWeight={700}>
							Distribution of the clicks
						</Typography>
						<Toggle
							title="View by"
							listView={[
								{
									label: "Month",
									value: "month",
								},
								{
									label: "Quarter",
									value: "quarter",
								},
							]}
							activeView={viewBy}
							//@ts-ignore
							setActiveView={(newView) => {
								if (newView && newView !== viewBy)
									setViewBy(newView as ViewByT);
							}}
							isDisabled={containFullQuarter}
						></Toggle>
					</Box>
					<BarsChartClicks
						sortedByDate={sortedByDate}
						selectedYear={selectedYear}
						viewBy={viewBy}
					/>

					<SimpleTable
						dataTestid="clickabilityTableMounthly"
						headers={[
							{ label: "Period", accessor: "period" },
							{
								label: "Clicked Volume (%)",
								accessor: "volume_percentage",
							},
							{
								label: "Available Volume (%)",
								accessor: "volume_available",
							},
							{
								label: "Action",
								accessor: "action",
							},
						]}
						items={sortedByDate[selectedYear][viewBy].map(
							(
								{
									period,
									click_start,
									volume_percentage,
									volume_available,
								}: MappedClicksDataT,
								index: number
							) => ({
								period:
									viewBy === "month"
										? format(new Date(click_start), "MMMM")
										: period,
								volume_percentage: volume_percentage.toFixed(2),
								volume_available: volume_available.toFixed(2),
								action: (
									<NJButton
										emphasis="subtle"
										onClick={() =>
											handleNewClick({
												...sortedByDate[selectedYear][
													viewBy
												][index],
												...totalVolume,
											})
										}
										label={"Add click"}
										isDisabled={volume_percentage === 100}
									/>
								),
							})
						)}
					/>
				</Box>
			</DialogContent>
			<DialogActions
				sx={{
					margin: "auto",
				}}
			>
				<NJButton onClick={handleClose} label="Close" icon="check">
					Close
				</NJButton>
			</DialogActions>
		</Dialog>
	);
}
