import {
	Box,
	Dialog,
	DialogContent,
	DialogTitle,
	Step,
	StepLabel,
	Stepper,
} from "@mui/material";
import { style } from "./style";
import { DateChangeStep } from "./DateChangeStep";
import { useEffect, useState } from "react";
import { Spacer } from "../../common/components/Spacer";
import { useHorizonChanges } from "../../common/hooks/useHandleHorizonChanges";
import { useRtkQueryDynamicEndpoint } from "../../common/hooks/useRtkQueryDynamicEndpoint";
import {
	useLazyGetContractPeriodHorizonChangeQuery,
	useUpdateContractPeriodHorizonChangeMutation,
} from "../../requests_cm/gecoContractsService/service";
import {
	ContractPeriodType,
	ErrorType,
	GetHorizonChangeParams,
} from "../../requests_cm/gecoContractsService/types";
import { formatApiErrorMessage } from "../../common/utils/formatApiErrorMessage";
import HorizonMonitoring from "./HorizonMonitoringStep";
import UpdateResultStep from "./UpdateResultStep";

export interface HorizonChangeProps {
	contractPeriod: ContractPeriodType | undefined;
	isOpen: boolean;
	onClose: () => void;
}

export const HorizonChange = ({
	contractPeriod,
	isOpen,
	onClose,
}: HorizonChangeProps) => {
	const [currentStep, setCurrentStep] = useState(0);
	const [currentHorizonParameters, setCurrentHorizonParameters] = useState<
		GetHorizonChangeParams | undefined
	>();
	const [
		baseUpdateHorizonChange,
		{
			error: errorUpdate,
			isLoading: isUpdateLoading,
			isSuccess: isUpdateSuccess,
		},
	] = useUpdateContractPeriodHorizonChangeMutation();
	const [
		baseGetHorizonChange,
		{
			data: proposedHorizonChanges,
			error: errorGet,
			isLoading: isFetchLoading,
		},
	] = useLazyGetContractPeriodHorizonChangeQuery();

	const getHorizonChange = useRtkQueryDynamicEndpoint(baseGetHorizonChange);
	const updateHorizonChange = useRtkQueryDynamicEndpoint(
		baseUpdateHorizonChange
	);

	const { entities, entitiesMap, updatedHorizonChanges, updateSingleEntity } =
		useHorizonChanges(proposedHorizonChanges?.proposed_changes || []);

	useEffect(() => {
		if (currentHorizonParameters) {
			getHorizonChange({
				...currentHorizonParameters,
				old_value: currentHorizonParameters.old_value,
				new_value: currentHorizonParameters.new_value,
			});
		}
	}, [currentHorizonParameters]);

	useEffect(() => {
		if (proposedHorizonChanges) {
			setCurrentStep(1);
		}
	}, [proposedHorizonChanges]);

	useEffect(() => {
		// reset on close
		if (!isOpen) {
			setCurrentStep(0);
			setCurrentHorizonParameters(undefined);
		}
	}, [isOpen]);

	useEffect(() => {
		if (isUpdateLoading) {
			setCurrentStep(2);
		}
	}, [isUpdateLoading]);

	const checkForHorizonChange = ({
		field_name,
		old_value,
		new_value,
		turn_safety_off,
		realign_related_contracts,
	}: Partial<GetHorizonChangeParams>) => {
		setCurrentHorizonParameters({
			contract_period_id: contractPeriod?.id!,
			field_name: field_name!,
			old_value: old_value!,
			new_value: new_value!,
			turn_safety_off: !!turn_safety_off,
			realign_related_contracts: !!realign_related_contracts,
		});
	};

	const confirmHorizonChanges = () => {
		updateHorizonChange({
			contractPeriod_id: contractPeriod?.id!,
			proposed_changes: updatedHorizonChanges,
		});
	};

	return (
		<Dialog maxWidth="lg" onClose={onClose} open={isOpen}>
			<DialogTitle>{"Change Horizon of contract period"}</DialogTitle>
			<DialogContent>
				<Stepper activeStep={currentStep} sx={style.stepperHeader}>
					<Step key={0}>
						<StepLabel>Update Date Field</StepLabel>
					</Step>
					<Step key={1}>
						<StepLabel>Horizon Monitoring</StepLabel>
					</Step>
					<Step key={2}>
						<StepLabel>Update Horizon</StepLabel>
					</Step>
				</Stepper>
				{currentStep === 0 && (
					<Box sx={style.container}>
						<DateChangeStep
							onSubmit={checkForHorizonChange}
							onCancel={() => onClose()}
							contractPeriod={contractPeriod}
							error={formatApiErrorMessage(errorGet as ErrorType)}
							isLoading={isFetchLoading}
						/>
					</Box>
				)}
				{currentStep === 1 && (
					<Box sx={style.container}>
						<Spacer gap={24} />
						<HorizonMonitoring
							updateSingleEntity={updateSingleEntity}
							entities={entities}
							entitiesMap={entitiesMap}
							onConfirm={confirmHorizonChanges}
							onGoBack={() => setCurrentStep(0)}
						/>
					</Box>
				)}
				{currentStep === 2 && (
					<Box sx={style.container}>
						<Spacer gap={24} />
						<UpdateResultStep
							onGoBack={() => setCurrentStep(1)}
							onClose={onClose}
							errorMessage={formatApiErrorMessage(
								errorUpdate as ErrorType
							)}
							isLoading={isUpdateLoading}
							isSuccess={isUpdateSuccess}
						/>
					</Box>
				)}
			</DialogContent>
		</Dialog>
	);
};
