import {
	addMonths,
	compareAsc,
	getQuarter,
	format,
	parseISO,
	startOfYear,
	endOfYear,
	isWithinInterval,
	subDays,
	startOfQuarter,
	endOfQuarter,
} from "date-fns";

import type {
	ConvertToMonthParamsT,
	MappedClicksDataByYearT,
	MappedClicksDataT,
} from "./types";
import type {
	ContractPeriodClickObjectType,
	ContractPeriodType,
} from "../../../../../requests_cm/gecoContractsService/types";
import type { ClickTypesResponseType } from "../../../../../requests_cm/gecoReferentialService/types";

function sortDate(dataMappedToMonth: Array<MappedClicksDataT>) {
	return dataMappedToMonth.sort((a, b) =>
		compareAsc(parseISO(a.click_start), parseISO(b.click_start))
	);
}

function convertToMonth(
	{ monthlyID, quarterID, dataMappedByYear = {} }: ConvertToMonthParamsT,
	click: ContractPeriodClickObjectType
) {
	const clickStart = new Date(click.click_start);
	const endDate = new Date(click.click_end);
	const monthsDiff =
		(endDate.getFullYear() - clickStart.getFullYear()) * 12 +
		endDate.getMonth() -
		clickStart.getMonth();
	const volumeAbsolute = click.volume_absolute / monthsDiff;

	while (clickStart < endDate) {
		const year = clickStart.getFullYear().toString();
		const quarterMapping: Record<
			number,
			{ click_start: string; click_end: string }
		> = {
			1: {
				click_start: `${year}-01-01`,
				click_end: `${year}-03-31`,
			},
			2: {
				click_start: `${year}-04-01`,
				click_end: `${year}-06-30`,
			},
			3: {
				click_start: `${year}-07-01`,
				click_end: `${year}-09-30`,
			},
			4: {
				click_start: `${year}-10-01`,
				click_end: `${year}-12-31`,
			},
		};
		if (!dataMappedByYear[year]) {
			dataMappedByYear[year] = {
				month: [],
				quarter: [],
			};
		}

		const formattedClickStartedDate = format(clickStart, "yyyy-MM-dd");
		const formattedNextMonth = format(
			addMonths(new Date(clickStart), 1),
			"yyyy-MM-dd"
		);

		const monthData = dataMappedByYear[year].month;
		const existingMonthEntry = monthData.find(
			(item) => item.click_start === formattedClickStartedDate
		);

		if (existingMonthEntry) {
			existingMonthEntry.volume_percentage += click.volume_percentage;
			existingMonthEntry.volume_absolute += volumeAbsolute;
			existingMonthEntry.volume_available = Math.round(
				(1 - existingMonthEntry.volume_percentage * 0.01) * 100
			);
		} else {
			monthData.push({
				...click,
				click_period_id: monthlyID,
				volume_absolute: volumeAbsolute,
				click_start: formattedClickStartedDate,
				click_end: formattedNextMonth,
				volume_available: Math.round(
					(1 - click.volume_percentage * 0.01) * 100
				),
			});
		}

		const quarterData = dataMappedByYear[year].quarter;
		const startQuarter = getQuarter(clickStart);

		if (!quarterData[startQuarter]) {
			quarterData[startQuarter] = {
				period: `Q${startQuarter}`,
				...click,
				click_period_id: quarterID,
				...quarterMapping[startQuarter],
				volume_percentage: click.volume_percentage / 3,
				volume_absolute: volumeAbsolute,
				volume_available: Math.round(
					(1 - (click.volume_percentage / 3) * 0.01) * 100
				),
			};
		} else {
			quarterData[startQuarter].volume_percentage +=
				click.volume_percentage / 3;
			quarterData[startQuarter].volume_absolute += volumeAbsolute;
			quarterData[startQuarter].volume_available = Math.round(
				(1 - quarterData[startQuarter].volume_percentage * 0.01) * 100
			);
		}

		clickStart.setMonth(clickStart.getMonth() + 1);
	}

	return dataMappedByYear;
}

export function fillMissingMonths(
	dataMappedByYear: MappedClicksDataByYearT,
	{
		monthlyID,
		quarterID,
		contractPeriodStartDate,
		contractPeriodEndDate,
	}: {
		monthlyID: ConvertToMonthParamsT["monthlyID"];
		quarterID: ConvertToMonthParamsT["quarterID"];
		contractPeriodStartDate: ContractPeriodType["start_date"];
		contractPeriodEndDate: ContractPeriodType["end_date"];
	}
) {
	let LastYear;
	const dataMappedByYearKeys = Object.keys(dataMappedByYear);
	const [FirstYear, ...rest] = dataMappedByYearKeys.sort();
	if (dataMappedByYearKeys.length > 1) {
		LastYear = rest.pop();
	} else {
		LastYear = FirstYear;
	}

	const earliestDate = startOfYear(new Date(Number(FirstYear), 1, 1));
	const latestDate = endOfYear(new Date(Number(LastYear), 1, 1));
	const currentDate = earliestDate;

	const contractStart = new Date(contractPeriodStartDate);
	const contractEnd = subDays(new Date(contractPeriodEndDate), 1);

	while (earliestDate <= latestDate) {
		const year = currentDate.getFullYear().toString();
		const quarterMapping: Record<
			number,
			{ click_start: string; click_end: string }
		> = {
			1: {
				click_start: `${year}-01-01`,
				click_end: `${year}-03-31`,
			},
			2: {
				click_start: `${year}-04-01`,
				click_end: `${year}-06-30`,
			},
			3: {
				click_start: `${year}-07-01`,
				click_end: `${year}-09-30`,
			},
			4: {
				click_start: `${year}-10-01`,
				click_end: `${year}-12-31`,
			},
		};
		const formattedCurrentDate = format(currentDate, "yyyy-MM-dd");
		const formattedNextMonth = format(
			addMonths(currentDate, 1),
			"yyyy-MM-dd"
		);

		const isWithinContractPeriod = isWithinInterval(currentDate, {
			start: contractStart,
			end: contractEnd,
		});

		const initialData = {
			id: 1,
			mid_price: 0,
			price: 0,
			ot_sync: true,
			click_type: "OTC",
			last_sync_update: formattedCurrentDate,
			click_date: formattedCurrentDate,
			volume_percentage: 0,
			volume_absolute: 0,
			volume_available: 100,
		};

		if (dataMappedByYear[year] && isWithinContractPeriod) {
			const monthExists = dataMappedByYear[year].month.some(
				(item) => item.click_start === formattedCurrentDate
			);

			if (!monthExists) {
				dataMappedByYear[year].month.push({
					...initialData,
					click_start: formattedCurrentDate,
					click_end: formattedNextMonth,
					click_period_id: monthlyID,
				});
			}

			const currentQuarter = getQuarter(currentDate);

			if (!dataMappedByYear[year].quarter[currentQuarter]) {
				dataMappedByYear[year].quarter[currentQuarter] = {
					...initialData,
					...quarterMapping[currentQuarter],
					period: `Q${currentQuarter}`,
					click_period_id: quarterID,
				};
			}
		}

		dataMappedByYear[year].month = sortDate(dataMappedByYear[year].month);
		currentDate.setMonth(currentDate.getMonth() + 1);
	}

	return dataMappedByYear;
}

export function mappingData(
	data: Array<ContractPeriodClickObjectType>,
	periodList: Array<ClickTypesResponseType>,
	contractPeriod: ContractPeriodType
) {
	let dataMappedByYear: MappedClicksDataByYearT = {};

	const monthlyID = Number(
		periodList.find(({ ot_name }) => ot_name === "Month")?.id
	);
	const quarterID = Number(
		periodList.find(({ ot_name }) => ot_name === "Quarter")?.id
	);

	data.forEach((click) => {
		convertToMonth(
			{
				dataMappedByYear,
				monthlyID,
				quarterID,
			},
			click
		);
	});

	dataMappedByYear = fillMissingMonths(dataMappedByYear, {
		contractPeriodStartDate: contractPeriod.start_date,
		contractPeriodEndDate: contractPeriod.end_date,
		monthlyID,
		quarterID,
	});

	return dataMappedByYear;
}

export function totalVolumePerYear(dataMappedByYear: Array<MappedClicksDataT>) {
	const addVolumePercentage = dataMappedByYear.reduce(
		(accumulator, currentValue) => {
			return {
				volume_percentage:
					accumulator.volume_percentage +
					currentValue.volume_percentage,
				volume_absolute:
					accumulator.volume_absolute + currentValue.volume_absolute,
			};
		},
		{
			volume_percentage: 0,
			volume_absolute: 0,
		}
	);
	const {
		totalVolumePercentage,
		totalVolumeAbsolute,
		availableVolumePercentage,
	} = {
		totalVolumePercentage: (
			addVolumePercentage.volume_percentage / 12
		).toFixed(2),
		totalVolumeAbsolute: addVolumePercentage.volume_absolute.toFixed(2),
		availableVolumePercentage: (
			100 -
			addVolumePercentage.volume_percentage / 12
		).toFixed(2),
	};
	return {
		totalVolumePercentage,
		totalVolumeAbsolute,
		availableVolumePercentage,
		availableVolumeAbsolute: (
			(Number(totalVolumeAbsolute) * Number(availableVolumePercentage)) /
			Number(totalVolumePercentage)
		).toFixed(2),
	};
}

export function isDateRangeInAnyQuarter(startDate: Date, endDate: Date) {
	const quarterStart = startOfQuarter(startDate);
	const quarterEnd = endOfQuarter(startDate);

	return (
		isWithinInterval(startDate, { start: quarterStart, end: quarterEnd }) &&
		isWithinInterval(endDate, { start: quarterStart, end: quarterEnd })
	);
}
